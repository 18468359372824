import { useGlobalStore } from '@/store/global'

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('outside-click', {
      mounted (el, binding, vnode, prevVnode) {

        const store = useGlobalStore()
        
        el.clickOutsideEvent = function(event) {

          if(event.target != el && !el.contains(event.target) && !event.target.classList.contains("dropdown")){
            if(binding.value == store.getDropdown){
              store.toggleDropdown(false)
            }
          }

        };

        document.body.addEventListener('click', el.clickOutsideEvent);
        

      },
      unmounted(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      }
    })
})