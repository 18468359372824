export default defineNuxtPlugin(nuxtApp => {

    /**
     *  Google Scripts
     */
    var script = document.createElement("script");
    script.setAttribute('async', '');
    script.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-M4KBEHLNFL');

    var scriptGtag = document.createElement("script");
    scriptGtag.text = '\window.dataLayer = window.dataLayer || [];\
    function gtag(){dataLayer.push(arguments);}\
    gtag(\'js\', new Date());\
    \
    gtag(\'config\', \'G-M4KBEHLNFL\');';
    
    const { cookiesEnabledIds } = useCookieControl()

    if(cookiesEnabledIds.value?.includes("google")){

        document.head.appendChild(script);
        document.head.appendChild(scriptGtag);

    }

    watch(
        () => cookiesEnabledIds.value,
        (current) => {
            if (current?.includes('google')){

                document.head.appendChild(script);
                document.head.appendChild(scriptGtag);

            }else {

                document.head.removeChild(script);
                document.head.removeChild(scriptGtag);

                const analyticsCookie = useCookie('_ga')
                const analyticsCookieWithID = useCookie('_ga_M4KBEHLNFL')
                analyticsCookie.value = null
                analyticsCookieWithID.value = null

            }
        },
        { 
            deep: true 
        }
    )

})