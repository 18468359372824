import { default as _91_91endVerse_93_93HGnngr0bTiMeta } from "/codebuild/output/src2726687986/src/biblia-frontend/src/pages/citanie/[translation]/[book]/[chapter]/[[verse]]/[[endVerse]].vue?macro=true";
import { default as _91title_93F0QVrMyqeEMeta } from "/codebuild/output/src2726687986/src/biblia-frontend/src/pages/clanky/[title].vue?macro=true";
import { default as indexo8hRSrTij7Meta } from "/codebuild/output/src2726687986/src/biblia-frontend/src/pages/index.vue?macro=true";
import { default as _91title_93b87r1RE2JCMeta } from "/codebuild/output/src2726687986/src/biblia-frontend/src/pages/verse/[title].vue?macro=true";
import { default as vyhladavaniedmB4Wlhs9UMeta } from "/codebuild/output/src2726687986/src/biblia-frontend/src/pages/vyhladavanie.vue?macro=true";
export default [
  {
    name: "citanie",
    path: "/citanie/:translation()/:book()/:chapter()/:verse?/:endVerse?",
    meta: _91_91endVerse_93_93HGnngr0bTiMeta || {},
    component: () => import("/codebuild/output/src2726687986/src/biblia-frontend/src/pages/citanie/[translation]/[book]/[chapter]/[[verse]]/[[endVerse]].vue")
  },
  {
    name: "clanky",
    path: "/clanky/:title()",
    meta: _91title_93F0QVrMyqeEMeta || {},
    component: () => import("/codebuild/output/src2726687986/src/biblia-frontend/src/pages/clanky/[title].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexo8hRSrTij7Meta || {},
    component: () => import("/codebuild/output/src2726687986/src/biblia-frontend/src/pages/index.vue")
  },
  {
    name: "verse",
    path: "/verse/:title()",
    meta: _91title_93b87r1RE2JCMeta || {},
    component: () => import("/codebuild/output/src2726687986/src/biblia-frontend/src/pages/verse/[title].vue")
  },
  {
    name: "vyhladavanie",
    path: "/vyhladavanie",
    component: () => import("/codebuild/output/src2726687986/src/biblia-frontend/src/pages/vyhladavanie.vue")
  }
]