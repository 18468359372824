<template>

    <div class="p-2 fixed flex flex-col items-stretch justify-center left-0 right-0 top-0 bottom-0 m-auto">
        <span class="font-bold text-6xl text-center">{{ error.statusCode }}</span>
        <span class="font-bold text-2xl text-center" v-if="error.statusCode == 404">Stránku, ktorú hľadáte, sa nenašla.</span>
        <span class="font-bold text-2xl text-center" v-else>Vyskytla sa neočakávaná chyba.</span>
        <button class="p-2 text-blue-500 underline hover:text-blue-600" @click="handleError">Ísť domov</button>  
    </div>

</template>
<script setup>

    const props = defineProps({
        error: Object
    })
    
    const handleError = () => {
        clearError({ redirect: '/' })
    }
    
</script>