import type { RouterConfig } from "@nuxt/schema"

export default <RouterConfig>{
    scrollBehavior(savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {
                top: 0,
                behavior: "smooth",
            }
        }
    },
    routes: (_routes) => {
        const routes = _routes

        // @ts-ignore
        routes.push({
            path: "/citovanie/:translation/:book/:chapter/:verse/:endVerse?",
            redirect: (to: any) => {
                let path = `/citanie/${to.params.translation}/${to.params.book}/${to.params.chapter}/${to.params.verse}`

                if (to.params.endVerse) {
                    path = path + "/" + to.params.endVerse
                }

                return { path: path }
            },
        })

        // @ts-ignore
        routes.push({
            path: "/porovnanie/:translation/:book/:chapter/:verse/:endVerse?",
            redirect: (to: any) => {
                let path = `/citanie/${to.params.translation}/${to.params.book}/${to.params.chapter}/${to.params.verse}`

                if (to.params.endVerse) {
                    path = path + "/" + to.params.endVerse
                }

                return { path: path }
            },
        })

        return routes
    },
}
