import { defineStore } from 'pinia'

interface Translation {
    id: number,
    identifier: string,
    name: string,
    copyright: string,
    bibliography: string,
    books: Array<any>
}

interface TranslationsState {
    translations: Array<any>
}

export const useTranslationsStore = defineStore('translations', {
    state: (): TranslationsState => ({
        translations: [],
    }),
    actions: {
        async fetchTranslations(){

            const translations: Array<Translation> = await $fetch('/api/translations')
            this.translations = translations

        }
    }
})