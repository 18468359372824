export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('footnote-click', {
      mounted (el, binding, vnode, prevVnode) {

        el.footnoteClick = function(event) {

            if(!event.target.parentElement) return;

            // xo - tlačitko na referenciu
            // fr - tlačitko na poznámku pod čiarou
            const footnoteButtons = event.target.parentElement.querySelectorAll('.xo, .fr');

            if(!footnoteButtons.length) return;

            // ak event.target sa rovná tlačidlu pre referenciu/poznámku
            const clickedButton = Array.from(footnoteButtons).find(button => {
                return button == event.target
            })

            /**
             *  Zatvorí všetky otvorené referencie s triedou "active-reference"
             *  Môže byť definované aj dontClose ako tá, ktorú nezatvárať
             */
            const closeAllActiveReferences = (references, dontClose) => {

                Array.from(references).forEach(activeItem => {

                    if(activeItem != dontClose){
                        activeItem.classList.remove("active-reference")
                        activeItem.classList.add("invisible")
                        activeItem.classList.add("opacity-0")
                    }

                });

            }

            const closeAllActiveButtons = (buttons, button) => {

                Array.from(buttons).forEach(activeButton => {

                    if(activeButton != button){
                        activeButton.classList.remove("active-reference-button")
                        activeButton.classList.remove("bg-sky-500")
                        activeButton.classList.remove("text-white")
                    }

                });

            }

            /**
             *  Ak sa stlačí tlačidlo referencie, otvorí sa obsah
             */
            if(clickedButton){

                const type = clickedButton.className.split(" ")[0];
                const classSelector = type == "xo" ? ".xt-reference" : ".ft"
                const content = clickedButton.parentElement.querySelector(classSelector);

                if(content.classList.contains("active-reference")){

                    content.classList.remove("active-reference")
                    content.classList.add("invisible")
                    content.classList.add("opacity-0")

                    clickedButton.classList.remove("active-reference-button")
                    clickedButton.classList.remove("bg-sky-500")
                    clickedButton.classList.remove("text-white")

                }else {

                    content.classList.add("active-reference")
                    content.classList.remove("invisible")
                    content.classList.remove("opacity-0")

                    clickedButton.classList.add("active-reference-button")
                    clickedButton.classList.add("bg-sky-500")
                    clickedButton.classList.add("text-white")

                }

                const activeContent = document.body.querySelectorAll('.active-reference');
                closeAllActiveReferences(activeContent, content)
                const activeButtons = document.body.querySelectorAll('.active-reference-button');
                closeAllActiveButtons(activeButtons, clickedButton)

            }

            /**
             *  Ak sa stlačí kdekoľvek inde, všetky referencie sa zatvoria
             */
            if(
                !event.target.classList.contains("xo") &&
                !event.target.classList.contains("fr") &&
                !event.target.classList.contains("xt-reference") &&
                !event.target.classList.contains("xt") &&
                !event.target.classList.contains("ft")
            ){
                const activeContent = document.body.querySelectorAll('.active-reference');
                closeAllActiveReferences(activeContent)

                const activeButtons = document.body.querySelectorAll('.active-reference-button');
                closeAllActiveButtons(activeButtons)

            }
            
        };

        document.body.addEventListener('click', el.footnoteClick);

      },
      unmounted(el) {

        document.body.removeEventListener('click', el.footnoteClick);
        
      }
    })
})